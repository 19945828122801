import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Our Process',
      paragraph: 'Our team has crafted a rigous interview process to help you waste less time with on-site interviews that don\'t lead to offers.  We can get from a job description to butts-in-seats within 4-5 weeks.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="Step 1">
                OneHire receives your job description for the requisition.
              </TimelineItem>
              <TimelineItem title="Step 2 (Sourcing: 1-7 days)">
                Our network of recruiters curate a list of qualified candidates.
              </TimelineItem>
              <TimelineItem title="Step 3 (Technical assessment: 7-10 days)">
              Our team of engineers screen each candidate rigorously to ensure individuals possess top-quality skills.
              </TimelineItem>
              <TimelineItem title="Step 4 (On-Site Interview: 7-10 days)">
                Our engineering team picks the top candidates and schedules an on-site with you.
              </TimelineItem>
              <TimelineItem title="Step 5">
                Your team completes the on-site with the candidate.
              </TimelineItem>
              <TimelineItem title="Step 6">
                You hire only the candidates that you love. 
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;