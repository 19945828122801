import React from 'react';
// import section header
// import sections
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
// import Testimonial from '../components/sections/Testimonial';
// import Cta from '../components/sections/Cta';
// import some required elements

class Home extends React.Component {

  state = {
    videoModalActive: false
  }
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {

    return (
      <React.Fragment>
        <HeroSplit className="illustration-section-01" />
        {/* <Clients topDivider bottomDivider /> */}
        {/* <GenericSection topDivider className="center-content">
          <SectionHeader data={genericSection01Header} className="reveal-from-bottom" />
        </GenericSection>          */}
        <FeaturesTiles topDivider className="center-content" />
        {/* <Testimonial topDivider />   */}
        {/* <Cta split /> */}
      </React.Fragment>
    );
  }
}

export default Home;